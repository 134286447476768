const moment = require('moment');
const {
    default: menuTranslations,
} = require('../components/Language/menuTranslations');
const {
    default: step0Translations,
} = require('../components/Language/step0Translations');
const {
    default: menuItemPublicTranslations,
} = require('../components/Language/menuItemPublicTranslations');
const {
    default: allergenTranslations,
} = require('../components/Language/allergenTranslations');
const {
    default: step1Translations,
} = require('../components/Language/step1Translations');
const {
    default: offersAvailableTranslation,
} = require('../components/Language/offersAvailableTranslation');
const {
    default: loginAccountTranslations,
} = require('../components/Language/loginAccountTranslations');
const {
    default: accountPointsTranslations,
} = require('../components/Language/accountPointsTranslations');
const {
    default: payLaterTranslations,
} = require('../components/Language/payLaterTranslations');
const {
    default: menuCardTranslations,
} = require('../components/Language/menuCardTranslations');

const hasLowerCase = str => str.toUpperCase() != str;

const hasUpperCase = str => str.toLowerCase() != str;

const hasSpecial = str => {
    const regexp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return regexp.test(str);
};

const getTranslation = (key, menuLanguage) => {
    return menuTranslations[menuLanguage]?.[key] || menuTranslations['en'][key];
};

const getTranslationStep0 = (key, menuLanguage) => {
    return (
        step0Translations[menuLanguage]?.[key] || step0Translations['en'][key]
    );
};

const getTranslationMenuItemPublic = (key, menuLanguage) => {
    return (
        menuItemPublicTranslations[menuLanguage]?.[key] ||
        menuItemPublicTranslations['en'][key]
    );
};

const getAllergenTranslations = (key, menuLanguage) => {
    return (
        allergenTranslations[menuLanguage]?.[key] ||
        allergenTranslations['en'][key]
    );
};

const getStep1Translations = (key, menuLanguage) => {
    return (
        step1Translations[menuLanguage]?.[key] || step1Translations['en'][key]
    );
};

const getOffersAvailableTranslations = (key, menuLanguage) => {
    return (
        offersAvailableTranslation[menuLanguage]?.[key] ||
        offersAvailableTranslation['en'][key]
    );
};

const getLoginAccountTranslations = (key, menuLanguage) => {
    return (
        loginAccountTranslations[menuLanguage]?.[key] ||
        loginAccountTranslations['en'][key]
    );
};

const getAccountsPointOffersTranslations = (key, menuLanguage) => {
    return (
        accountPointsTranslations[menuLanguage]?.[key] ||
        accountPointsTranslations['en'][key]
    );
};

const getPayLaterPopupTranslations = (key, menuLanguage) => {
    return (
        payLaterTranslations[menuLanguage]?.[key] ||
        payLaterTranslations['en'][key]
    );
};

const getMenuCardTranslations = (key, menuLanguage) => {
    return (
        menuCardTranslations[menuLanguage]?.[key] ||
        menuCardTranslations['en'][key]
    );
};

const isoStringWithMinuteOffset = minutes =>
    new Date(new Date().getTime() - minutes * 60000).toISOString();

const secondsToMinAgo = seconds => {
    const msec = seconds * 1000;
    return msecToMinsAgo(msec);
};

const isFirefox = () => {
    return typeof InstallTrigger !== 'undefined';
};

const msecToMinsAgo = msec => {
    const hh = `${Math.floor(msec / 1000 / 60 / 60)}`;
    msec -= hh * 1000 * 60 * 60;

    const mm = `${Math.floor(msec / 1000 / 60)}`;
    msec -= mm * 1000 * 60;

    const ss = `${Math.floor(msec / 1000)}`;
    msec -= ss * 1000;
    return `${hh !== '0' ? `${hh.slice(-2)}h` : ''} ${mm.slice(-2)}m ${ss.slice(
        -2
    )}s`;
};

const calculateMinsAgo = fromDate => {
    const date2 = new Date();

    const diff = date2.getTime() - fromDate.getTime();

    const msec = diff;

    return msecToMinsAgo(msec);
};

const priceToStrFormat = (
    price,
    currency,
    useComma,
    avoidPound = false,
    useThousandsSeparator = false,
    ignoreIDRFormatting = false
) => {
    let amount = price / 100; // Convert cents to dollars for most currencies
    let decimalPlaces = 2; // Default decimal places

    // Adjust amount and decimal handling specifically for IDR
    if (currency.toLowerCase() === 'idr' && !ignoreIDRFormatting) {
        amount = price / 100000; // Convert to rupiah directly (dividing by 100,000 instead of 100)
        decimalPlaces = amount % 1 === 0 ? 0 : 1; // No decimals if the number is whole, otherwise show one decimal place
    }

    const formattedAmount = amount.toFixed(decimalPlaces);

    // Determine currency symbol
    const symbol = avoidPound
        ? ''
        : currency.toLowerCase() === 'gbp'
        ? '£'
        : currency.toLowerCase() === 'aed'
        ? 'AED '
        : currency.toLowerCase() === 'usd'
        ? '$'
        : currency.toLowerCase() === 'thb'
        ? '฿'
        : currency.toLowerCase() === 'idr'
        ? 'IDR '
        : currency.toLowerCase() === 'sar'
        ? 'SAR '
        : currency.toLowerCase() === 'egp'
        ? 'EGP '
        : currency.toLowerCase() === 'omr'
        ? 'OMR '
        : currency.toLowerCase() === 'jod'
        ? 'JOD '
        : currency.toLowerCase() === 'cad'
        ? 'CA$'
        : '€';

    // Combine symbol with amount
    let formattedPrice = `${symbol}${formattedAmount}`;

    // Insert thousands separator if needed
    if (useThousandsSeparator) {
        const parts = formattedPrice.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        formattedPrice = parts.join(useComma ? ',' : '.');
    }

    return formattedPrice;
};

// const priceToStrFormat = (price, avoidPound = false) => `${!avoidPound ? '£' : ''}${(price / 100).toFixed(2)}`;
const isAlphaNumericDashUnderscore = str => {
    const regex = /^[\w\-'.,?\/()\[\]!&%\s;:!\?$#£@*\(\)\-+ñáéíóúÑÁÉÍÓçÇÚ]+$/;
    return regex.test(str);
};

const isNumber = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

const isNumeric = data => {
    return (
        !isNaN(parseFloat(data)) && isFinite(data) && data.constructor !== Array
    );
};

const getOperatingSystem = window => {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
        operatingSystem = 'Windows';
    }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) {
        operatingSystem = 'Mac';
    }
    if (window.navigator.appVersion.indexOf('X11') !== -1) {
        operatingSystem = 'UNIX';
    }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) {
        operatingSystem = 'Linux';
    }

    return operatingSystem;
};

const getDateObject = (startDate, stopDate) => {
    const out = {};
    let currentDate = moment(startDate);
    const endDate = stopDate;
    out[currentDate.format('MMM D, YYYY')] = 0;
    while (currentDate.isBefore(endDate, 'day')) {
        currentDate = currentDate.add(1, 'day');
        out[currentDate.format('MMM D, YYYY')] = 0;
    }
    return out;
};

const getDaysOfWeekBetweenDates = (startDate, stopDate) => {
    const out = {};
    let currentDate = moment(startDate);
    const endDate = stopDate;
    out[currentDate.day()] = 0;
    while (currentDate.isBefore(endDate, 'day')) {
        currentDate = currentDate.add(1, 'day');
        out[currentDate.day()] = 0;
    }
    return out;
};

const getDaysOfWeek = () => [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const getAllHours = () => [
    '12:00:00 am',
    '01:00:00 am',
    '02:00:00 am',
    '03:00:00 am',
    '04:00:00 am',
    '05:00:00 am',
    '06:00:00 am',
    '07:00:00 am',
    '08:00:00 am',
    '09:00:00 am',
    '10:00:00 am',
    '11:00:00 am',
    '12:00:00 pm',
    '01:00:00 pm',
    '02:00:00 pm',
    '03:00:00 pm',
    '04:00:00 pm',
    '05:00:00 pm',
    '06:00:00 pm',
    '07:00:00 pm',
    '08:00:00 pm',
    '09:00:00 pm',
    '10:00:00 pm',
    '11:00:00 pm',
];

module.exports = {
    hasLowerCase,
    hasUpperCase,
    hasSpecial,
    priceToStrFormat,
    secondsToMinAgo,
    isoStringWithMinuteOffset,
    calculateMinsAgo,
    isAlphaNumericDashUnderscore,
    isNumber,
    getOperatingSystem,
    isFirefox,
    isNumeric,
    getDateObject,
    getDaysOfWeekBetweenDates,
    getDaysOfWeek,
    getAllHours,
    getTranslation,
    getTranslationStep0,
    getTranslationMenuItemPublic,
    getAllergenTranslations,
    getStep1Translations,
    getOffersAvailableTranslations,
    getLoginAccountTranslations,
    getAccountsPointOffersTranslations,
    getPayLaterPopupTranslations,
    getMenuCardTranslations,
};
